import React from "react";
import { useNavigate } from "react-router-dom";

const isLoggedIn = () => {
  return !!localStorage.getItem("accessToken");
};

const pricingPlans = [
  
  {
    title: "Students",
    price: "Free",
    description: "Access to more features with more compute allocation.",
    features: ["Full access to features", "More compute allocation than basic"],
    requirement: "University email verification required",
    pricingModel: "student",
    buttonText: "Choose plan",
  },
  {
    title: "Individual Access",
    price: "Pay as You Go",
    description: "More compute allocation and hardware access.",
    features: [
      "Pro features",
      "Access to more hardware",
      "No corporate affiliation",
    ],
    requirement: "Not associated with any Corporation",
    pricingModel: "pro",
    buttonText: "Choose plan",
  },
  {
    title: "Small Startup/Business",
    price: "Contact Us",
    description:
      "Start with $100-$2,000 in free credits, gain access to multiple hardware vendors.",
    features: [
      "Custom pricing and credits",
      "Access to wider range of hardware",
    ],
    requirement: "ARR < $1 million required",
    pricingModel: "startup",
    buttonText: "Book a Call",
    buttonLink: "https://calendar.app.google/VutFHCSsR2TmZx9Q8",
  },
  {
    title: "Enterprises",
    price: "Contact Us",
    description:
      "Get access to SOTA hardware, 24/7 engineering support, and follow-on credit top-ups.",
    features: [
      "Performance optimization + monitoring",
      "Access to best hardware",
      "24/7 engineering support",
      "Custom pricing and credits",
    ],
    requirement: "ARR >= $1 million required",
    pricingModel: "enterprise",
    buttonText: "Book a Call",
    buttonLink: "https://calendar.app.google/VutFHCSsR2TmZx9Q8",
  },
];

const Pricing = () => {
  const navigate = useNavigate();

  const handleChoosePlan = (pricingModel) => {
    if (!isLoggedIn()) {
      navigate("/login", { state: { redirectTo: `/pricing/${pricingModel}` } });
    } else {
      localStorage.setItem("selectedPricingModel", pricingModel);
      navigate("/dashboard");
    }
  };

  const handleBookCall = (url) => {
    window.open(url, "_blank");
  };

  return (
    <section className="bg-black dark:bg-black mt-20">
      <div className="container px-6 py-8 mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-teal-400">
            Simple, transparent pricing
          </h2>
          <p className="mt-4 text-gray-500 dark:text-gray-400">
            No Contracts. No surprise fees.
          </p>
        </div>

        <div className="grid gap-8 mt-16 sm:gap-8 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className="relative flex flex-col justify-between p-8 transition-colors duration-200 transform rounded-lg bg-black border border-teal-400 overflow-hidden group h-[600px] hover:shadow-lg hover:scale-105"
            >
              <div className="absolute inset-0 bg-[radial-gradient(164.75%_100%_at_50%_0%,_#334155_0%,_#0F172A_48.73%)] opacity-0 group-hover:opacity-60 transition-opacity duration-300 z-0"></div>

              <div className="flex flex-col justify-start h-1/6 relative z-10">
                <p className="text-lg font-medium text-teal-400">
                  {plan.title}
                </p>
                <h4 className="mt-2 text-3xl font-semibold text-white">
                  {plan.price}
                </h4>
              </div>

              <div className="flex flex-col justify-start my-5 h-1/6 mt-4 relative z-10">
                <p className="text-gray-500 dark:text-gray-300">
                  {plan.description}
                </p>
              </div>

              <div className="flex flex-col justify-start h-2/6 mt-4 relative z-10">
                <ul className="list-disc text-white space-y-2">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center">
                      <span className="inline-block w-2.5 h-2.5 mr-2 rounded-full bg-teal-400"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex flex-col justify-end mb-5 h-1/6 mt-4 relative z-10">
                <ul className="list-disc text-white space-y-2">
                  <li className="flex items-center">
                    <span className="inline-block w-2.5 h-2.5 mr-2 rounded-full bg-blue-600"></span>
                    <p>{plan.requirement}</p>
                  </li>
                </ul>
              </div>

              {plan.buttonText === "Book a Call" ? (
                <button
                  onClick={() => handleBookCall(plan.buttonLink)}
                  className="relative z-10 mt-4 bottom-0 left-1/2 transform -translate-x-1/2 font-medium bg-white text-center text-black py-2 px-4 rounded-full relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-teal-600 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
                >
                  <span className="relative z-10">{plan.buttonText}</span>
                </button>
              ) : (
                <button
                  onClick={() => handleChoosePlan(plan.pricingModel)}
                  className="relative z-10 mt-4 bottom-0 left-1/2 transform -translate-x-1/2 font-medium bg-white text-center text-black py-2 px-4 rounded-full overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-teal-600 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
                >
                  <span className="relative z-10">{plan.buttonText}</span>
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
