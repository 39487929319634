import React from "react";
import TypeIt from "typeit-react";

const TypingAnimation = () => {
  return (
    <div>
      <TypeIt
        options={{ speed: 50, waitUntilVisible: true, loop: true }}
        getBeforeInit={(instance) => {
          instance
            .type('<span class="font-extralight mb-48 text-3xl text-cyan-400"> Want to change how you think about hardware?</span>')
            .pause(750)
            .break()
            .type(
              '<span class="font-thin text-3xl"><span class="font-sm text-red-500">Begin your Journey</span> with <code class="font-thin text-2xl bg-gray-800 text-green-500 px-2 rounded">pip install dyson</code></span>'
            )
            .pause(3000)
            .delete()
            .pause(1000);
          return instance;
        }}
      />
    </div>
  );
};

export default TypingAnimation;
