import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ scrollToSection, refs }) => {
  const [scrollY, setScrollY] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({
    products: false,
    graphica: false,
    resources: false,
    about: false,
  });
  const [dropdownTimeout, setDropdownTimeout] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) setIsLoggedIn(true);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
    navigate("/login");
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setShowNavbar(currentScrollY <= scrollY);
    setScrollY(currentScrollY);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMouseEnter = (key) => {
    if (dropdownTimeout) clearTimeout(dropdownTimeout); 
    setDropdownStates((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const handleMouseLeave = (key) => {
    setDropdownTimeout(
      setTimeout(() => {
        setDropdownStates((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }, 30)
    );
  };

  const handleDropdownToggle = (key) => {
    setDropdownStates((prevState) => {
      const newState = { ...prevState };

      for (let dropdownKey in newState) {
        if (dropdownKey === key) {
          newState[dropdownKey] = !newState[dropdownKey]; 
        } else {
          newState[dropdownKey] = false; 
        }
      }
      return newState;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (dropdownTimeout) clearTimeout(dropdownTimeout);
    };
  }, [scrollY, dropdownTimeout]);

  const handleNavigation = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      setIsMobileMenuOpen(false);
    }
  };

  const renderDropdown = (title, items) => (
    <div
      className="relative group"
      onMouseEnter={() => handleMouseEnter(title.toLowerCase())}
      onMouseLeave={() => handleMouseLeave(title.toLowerCase())}
    >
      <Link
        to="#"
        className="px-4 py-2 text-white hover:text-teal-600"
        onClick={(e) => e.preventDefault()}
      >
        {title}
      </Link>
      <div
        className={`absolute w-28 mt-2 p-1 bg-gray-900 text-center border border-teal-600 rounded shadow-lg z-10 ${
          dropdownStates[title.toLowerCase()] ? "block" : "hidden"
        }`}
      >
        {items.map((item, idx) =>
          item.label === "GRAPHICA" ? (
            <div
              key={idx}
              className="relative group"
              onMouseEnter={() => handleMouseEnter("graphica")}
              onMouseLeave={() => handleMouseLeave("graphica")}
            >
              <Link
                to="#"
                className="block px-4 py-2 text-white hover:bg-teal-800"
                onClick={(e) => e.preventDefault()}
              >
                {item.label}
              </Link>
              <div
                className={`absolute left-[110px] p-1 top-0 mt-0 bg-gray-900 border border-teal-600 rounded shadow-lg ${
                  dropdownStates.graphica ? "block" : "hidden"
                }`}
              >
                {item.submenu.map((subitem, subidx) => (
                  <Link
                    key={subidx}
                    to={subitem.path || "#"}
                    className="block px-4 py-2 text-white hover:bg-teal-800"
                    onClick={
                      subitem.action
                        ? subitem.action
                        : (e) => {
                            e.preventDefault();
                            navigate(subitem.path);
                          }
                    }
                  >
                    {subitem.label}
                  </Link>
                ))}
              </div>
            </div>
          ) : item.external ? (
            <a
              key={idx}
              href={item.external}
              className="block px-4 py-2 text-white hover:bg-teal-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.label}
            </a>
          ) : (
            <Link
              key={idx}
              to={item.path || "#"}
              className="block px-4 py-2 text-white hover:bg-teal-800"
              onClick={
                item.action
                  ? item.action
                  : (e) => {
                      e.preventDefault();
                      navigate(item.path);
                    }
              }
            >
              {item.label}
            </Link>
          )
        )}
      </div>
    </div>
  );

  const renderMobileDropdown = (title, items) => (
    <div className="flex flex-col items-center">
      <button
        onClick={() => handleDropdownToggle(title.toLowerCase())}
        className="w-full text-center px-4 py-2 text-white hover:bg-teal-800"
      >
        {title}
      </button>
      {dropdownStates[title.toLowerCase()] && (
        <div className="w-full bg-gray-800">
          {items.map((item, idx) =>
            item.submenu ? (
              <div key={idx}>
                <button className="w-full px-4 py-2 text-white text-center hover:bg-teal-700">
                  {item.label}
                </button>
                <div className="pl-4">
                  {item.submenu.map((subitem, subidx) => (
                    <a
                      key={subidx}
                      href={subitem.path || "#"}
                      className="block px-4 py-2 text-white hover:bg-teal-600"
                      target={subitem.external ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                    >
                      {subitem.label}
                    </a>
                  ))}
                </div>
              </div>
            ) : item.external ? (
              <a
                key={idx}
                href={item.external}
                className="block px-4 py-2 text-white hover:bg-teal-700"
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            ) : (
              <a
                key={idx}
                href={item.path || "#"}
                className="block px-4 py-2 text-white hover:bg-teal-700"
                target={item.external ? "_blank" : "_self"}
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            )
          )}
        </div>
      )}
    </div>
  );

  return (
    <nav
      className={`fixed top-0 w-full z-50 text-center text-white p-1 transition-transform ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      }`}
      style={{ backgroundColor: "rgb(17, 17, 17)" }}
    >
      <div className="flex justify-between items-center mx-auto px-4 py-2">
        <Link to="/" className="flex items-center">
          <div
            className="bg-contain bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/logo.png)`,
              width: "30px",
              height: "40px",
            }}
          ></div>
          <span className="mx-2 text-2xl font-semibold navbar-logo">
            CrossGL
          </span>
        </Link>

        <div className="hidden md:flex items-center space-x-6 navbar-items">
          {renderDropdown("PRODUCTS", [
            { label: "DYSON", path: "/dyson" },
            {
              label: "GRAPHICA",
              submenu: [
                { label: "CrossVL", path: "/crossvl" },
                {
                  label: "CrossTL",
                  action: () => handleNavigation(refs.Translatorref),
                },
                {
                  label: "CrossGL Compiler",
                  action: () => handleNavigation(refs.Compilerref),
                },
              ],
            },
          ])}
          {renderDropdown("RESOURCES", [
            { label: "BLOG", path: "/blogs" },
            {
              label: "DOCS",
              external: "https://crossgl.github.io/crossgl-docs/",
            },
          ])}
          {renderDropdown("ABOUT", [
            { label: "CONTACT", path: "/contact" },
            { label: "CAREERS", path: "/careers" },
            { label: "GITHUB", external: "https://github.com/CrossGL" },
          ])}
        </div>

        <div className="hidden md:flex items-center space-x-6">
          {isLoggedIn && (
            <Link
              to="/dashboard"
              className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
            >
              Dashboard
            </Link>
          )}
          {isLoggedIn ? (
            <a
              href="#"
              onClick={handleLogout}
              className="px-5 py-2 border border-red-600 text-red-600 rounded hover:border-red-800 hover:text-white hover:bg-red-800"
            >
              Logout
            </a>
          ) : (
            <Link
              to="/login"
              className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
            >
              Login
            </Link>
          )}
        </div>

        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMobileMenu}
            className="text-teal-600 hover:text-teal-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="w-full flex flex-col items-center space-y-2 bg-gray-800 text-white">
          {renderMobileDropdown("PRODUCTS", [
            { label: "DYSON", path: "/dyson" },
            {
              label: "GRAPHICA",
              submenu: [
                { label: "CrossVL", path: "/crossvl" },
                {
                  label: "CrossTL",
                  action: () => handleNavigation(refs.Translatorref),
                },
                {
                  label: "CrossGL Compiler",
                  action: () => handleNavigation(refs.Compilerref),
                },
              ],
            },
          ])}
          {renderMobileDropdown("RESOURCES", [
            { label: "BLOG", path: "/blogs" },
            {
              label: "DOCS",
              external: "https://crossgl.github.io/crossgl-docs/",
            },
          ])}
          {renderMobileDropdown("ABOUT", [
            { label: "CONTACT", path: "/contact" },
            { label: "CAREERS", path: "/careers" },
            { label: "GITHUB", external: "https://github.com/CrossGL" },
          ])}
          {isLoggedIn && (
            <Link
              to="/dashboard"
              className="w-full text-center px-4 py-2 text-teal-500 hover:bg-teal-600"
            >
              Dashboard
            </Link>
          )}
          {isLoggedIn ? (
            <a
              href="#"
              onClick={handleLogout}
              className="w-full text-center px-4 py-2 text-red-500 hover:bg-red-700"
            >
              Logout
            </a>
          ) : (
            <Link
              to="/login"
              className="w-full text-center px-4 py-2 text-teal-500 hover:bg-teal-600"
            >
              Login
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
